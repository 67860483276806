<template>
  <div class="container">
    <div class="columns">
      <!-- Initial State -->
      <div
        v-if="state==='initial'"
        class="column content login-box card is-4 is-offset-4"
      >
        <div class="p-md">
          <div class="has-text-centered is-margin-bottom">
            <h2 class="">
              Recover Password
            </h2>
            <p>Please supply your e-mail address if you wish to choose a new password.</p>
          </div>
          <ValidationObserver
            v-slot="validation"
            tag="div"
          >
            <input-form :errors="validation.errors">
              <div class="field">
                <vee-textbox
                  v-model="emailAddress"
                  name="E-mail address"
                  placeholder="Enter your account e-mail"
                  type="email"
                  rules="required|email"
                  required
                />
              </div>
              <div class="control has-text-centered">
                <button
                  class="button is-primary"
                  :class="{ 'is-loading': doingAjax }"
                  @click.prevent="sendEmail(validation)"
                >
                  Submit
                </button>
              </div>
            </input-form>
          </ValidationObserver>
        </div>
      </div>

      <!-- Email Sent State -->
      <div
        v-if="state==='email-sent'"
        class="column content login-box card is-4 is-offset-4"
      >
        <div class="p-md">
          <div class="has-text-centered">
            <h3 class="">
              Next Step
            </h3>
            <p>
              If you have a <strong>Vendor Payments Account</strong>
              you will receive an email with password reset instructions to <strong>{{ emailAddress }}</strong>
            </p>
            <a :href="$router.resolve({ name: 'login' }).href">
              Go Back to login
            </a>
          </div>
        </div>
      </div>

      <!-- Validating token state -->
      <div
        v-if="state==='validating-token'"
        class="column content login-box card is-4 is-offset-4"
      >
        <div class="p-md">
          <div class="has-text-centered">
            <h3 class="">
              Please wait...
            </h3>
            <p>We are checking some information...</p>
          </div>
        </div>
      </div>

      <!-- Token is valid state -->
      <div
        v-if="state==='token-is-valid'"
        class="column content login-box card is-4 is-offset-4"
      >
        <div class="p-md">
          <div class="has-text-centered is-margin-bottom">
            <h3 class="">
              Change Password
            </h3>
            <p>Please enter your new password below.</p>
          </div>
          <ValidationObserver
            v-slot="validation"
            tag="div"
          >
            <!-- ref="reset-password"
          tag="form"
          class="small-form"
          @submit.prevent="resetPassword" -->
            <input-form :errors="validation.errors">
              <div class="field">
                <vee-textbox
                  id="password"
                  v-model="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  rules="required"
                  autocomplete="off"
                />
              </div>
              <div class="field">
                <vee-textbox
                  id="passwordConfirm"
                  v-model="confirmPassword"
                  name="confirmed password"
                  type="password"
                  rules="required|confirmed:password"
                  placeholder="Confirm Password"
                  autocomplete="off"
                />
              </div>
              <div class="control has-text-centered">
                <button
                  class="button is-primary"
                  :class="{ 'is-loading': doingAjax }"
                  @click.prevent="resetPassword(validation)"
                >
                  Submit
                </button>
              </div>
            </input-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputForm from '@/components/InputForm';

import {
  Textbox,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
  validate,
} from 'vee-validate';

import { required, email, confirmed } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);

export default {
  name: "LoginPage",
  components: {
    // InputText,
    VeeTextbox,
    ValidationObserver,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      doingAjax: false,
      state: 'initial',
      emailAddress: '',
    };
  },
  created () {
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
    extend('email', {
      ...email,
    });
    extend('confirmed', {
      ...confirmed,
    });
  },
  async beforeMount() {
    if (this.$route.query.token) {
      // Alright, we got a token, let's validate it and then display the
      // change password screen.
      this.state = 'validating-token';
      const isValid = await this.$store.dispatch('login/validateToken', this.$route.query.token);
      if (isValid) {
        this.state = 'token-is-valid';
      }
    }
  },
  methods: {
    async sendEmail(validation) {
      // const valid = await this.$refs['send-email'].validate();
      const valid = await validation.validate();
      if (!valid) {
        return false;
      }

      this.doingAjax = true;
      const data = await this.$store.dispatch('login/sentResetPasswordEmail', this.emailAddress);
      this.doingAjax = false;
      if (data) {
        this.state = 'email-sent';
      }
    },

    async resetPassword(validation) {
      console.log('ForgotPassword.vue resetPassword is running');
      const isValid = await validation.validate();
      // const isValid = await this.$refs['reset-password'].validate();
      if (!isValid) {
        return false;
      }

      this.doingAjax = true;
      const data = await this.$store.dispatch('login/resetPassword', {
        token: this.$route.query.token,
        password: this.password,
      });
      this.doingAjax = false;

      if(data) {
        this.$sCache.set('flash-message',
          { type: 'success', message: `Your password has been saved!` }
        );
        document.location = '/login';
      }
    },
  },
};
</script>
